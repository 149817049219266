<form [formGroup]="loginForm" (submit)="login()">

  <div class="card contain">
    <div class="row">
      <div class="col-md-6 col-lg-6 order-md-2 leftCard ">
        <img src="../../../../assets/Palmy images/palmy12.png" alt="Image" class="leftImage">
      </div>

      <div class="col-md-6 col-lg-6 order-md-1 rightCard">
        <div class="first-card">
          <h2 class="sign-in-card" style="margin-left: 110px;font-weight: bold;font-size: 32px;">Sign In</h2>
          <div class="card-body mb-3">
            <div class="form-group d-flex borderStyles">
              <img src="../../../../assets/icons/email.svg" alt="" class="img-card">
              <input type="email" class="form-control inputStyles rightcard1" placeholder="Username"
                formControlName="email">
            </div>
            <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
              <span *ngIf="f.email.errors.required">
                Email Required
              </span>
              <span *ngIf="f.email.errors.email">
                Invalid Email
              </span>
            </div>
            <div class="form-group d-flex  borderStyles">
              <img src="../../../../assets/icons/password.svg" alt="" class="password-card">
              <input [type]="hide ? 'password' : 'text'" class="form-control inputStyles rightCard2"
                placeholder="Password" formControlName="password">
              <mat-icon class="placeholder rightcard3" (click)="myFunction()">{{hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            </div>
            <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
              <span *ngIf="f.password.errors.required">
                Password Required
              </span>
              <span *ngIf="f.password.errors.minlength">
                Password must be six or more characters
              </span>
            </div>
            <div class="form-group mt-3 mb-5">
              <a href="#" class="forgot-password" routerLink="/auth/forgot-password">Forgot Password?</a>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-block">Sign In</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</form>