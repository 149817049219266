import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class DashboardService {
    url: any = environment.API_URL;
    header = {};

    constructor(
        private http: HttpClient
    ) { }

    getAdminDashboard() {
        return this.http.get(this.url + "api/user/get-admin-dashboard");
    }

    getManageLocalList(data: any) {
        return this.http.post(this.url + "user/admin/get", data)
    }


    getManageLocalById(data: any) {
        return this.http.post(this.url + 'user/get', data)
    }

}