import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  manageInterestdHeading: boolean = false;
  manageLocalHeading: boolean = false;
  manageOrganisationHeading: boolean = false;
  manageProjectsHeading: boolean = false;
  manageCategoriesHeading: boolean = false;
  dashboardHeading: boolean = false;
  changePasswordHeading: boolean = false;
  manageCouponHeading: boolean = false;
  manageContestHeading: boolean = false;
  default: boolean;
  lang: string;
  profilePic: String = "";
  modalRef: any;

  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {


    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });


    this._header.manageInterestdHeading.subscribe((res) => {
      this.manageInterestdHeading = res;
    });


    this._header.manageLocalHeading.subscribe((res) => {
      this.manageLocalHeading = res;
    });


    this._header.manageOrganisationHeading.subscribe((res) => {
      this.manageOrganisationHeading = res;
    });

    this._header.manageCategoriesHeading.subscribe((res) => {
      this.manageCategoriesHeading = res;
    });

    this._header.manageCouponHeading.subscribe((res) => {
      this.manageCouponHeading = res;
    });

    this._header.manageContestHeading.subscribe((res) => {
      this.manageContestHeading = res;
    });
  }

  userName: String;
  firstName: String;
  lastName: String;
  allDate: any;


  currentUser: any;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.default = true;
    this.lang = "English";
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.profilePic = "../../../assets/icons/user-img.svg";
    this.userName = this.authService.currentUserValue?.userDetails?.email?.toLowerCase();

  }


  logout() {
    this.modalRef.hide();

    this.authService.logout({ email: this.currentUser.email, password: this.currentUser.password }).subscribe((res: any) => {
      console.log(res.status);
      if (res.status == 200) {
        localStorage.removeItem("currentUser");
      }


    });
    this.router.navigateByUrl("/auth/login");
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }
}

