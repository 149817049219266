<app-layout></app-layout>
<div class="content">
    <div class="row mt-4">
        <div class="total-counts">
            <!-- <div class="total-count total-careers  col-lg-5 mt-3"
                routerLink="/analytics-dashboard/registered-experiencers"> -->
            <div class="total-count total-careers  col-lg-5 mt-3" style=" cursor: text;">
                <img src="../../../assets/Palmy images/users.svg" alt="" height="60" width="60">
                <div>
                    <h1 id="client">{{donorCount}}</h1>
                    <p>Total Number of Registered Donors/Volunteers</p>

                </div>
            </div>

            <!-- <div class="total-count total-careers  col-lg-5 mt-3" routerLink="/analytics-dashboard/registered-locals"> -->
            <div class="total-count total-careers  col-lg-5 mt-3" routerLink="/manage-organisations">

                <img src="../../../assets/Palmy images/users.svg" alt="" height="60" width="60">
                <div>
                    <h1 id="client1">{{organizationCount}}</h1>
                    <p>Total Number of Registered Organizations</p>
                </div>
            </div>



            <!-- <div class="total-count total-gs  col-lg-5 mt-3" routerLink="/manage/transactionhistory"> -->
            <div class="total-count total-gs  col-lg-5 mt-3" routerLink="/manage-projects">
                <img src="../../../assets/Palmy images/project.svg" alt="" height="60" width="60">
                <div>
                    <h1 id="client1">{{projectCount}}</h1>
                    <p>Total Number of Projects</p>
                </div>
            </div>

            <div class="total-count total-resources  col-lg-5 mt-3" style=" cursor: text;">
                <img src="../../../assets/Palmy images/dollor.svg" alt="" height="60" width="60">
                <div>
                    <h1 id="client12">$ {{(totalAmount | number:'1.0-0')}}</h1>
                    <p>Total Donation Amount Raised</p>
                </div>
            </div>
        </div>
    </div>
</div>