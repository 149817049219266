import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardAllData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;

  formattedNumber: any
  donorCount: any;
  organizationCount: any;
  projectCount: any;
  totalAmount: any;


  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.getCount()
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getCount() {
    this.dashboardService.getAdminDashboard().subscribe((res: any) => {
      if (res.status == 200) {
        this.donorCount = res.output.donorCount
        this.organizationCount = res.output.organizationCount
        this.projectCount = res.output.projectCount
        this.totalAmount = res.output.totalAmount
      }
    })
  }

  getFormattedNumber(value: any) {
    this.formattedNumber = value.toLocaleString('en-US');
    document.getElementById("client12").innerHTML = this.formattedNumber;
    const numberFormat = this.formattedNumber
    console.log(this.formattedNumber);
    return numberFormat
  }

  animateCounter(obj, initVal, lastVal, duration) {
    let startTime = null;


    let currentTime = Date.now();


    const step = (currentTime) => {


      if (!startTime) {
        startTime = currentTime;
      }


      const progress = Math.min((currentTime - startTime) / duration, 20);


      obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);


      if (progress < 1) {
        window.requestAnimationFrame(step);
      } else {
        window.cancelAnimationFrame(window.requestAnimationFrame(step));
      }
    };

    window.requestAnimationFrame(step);
  }

}
